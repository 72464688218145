<template>
  <div class="page-title">{{ content.updateYourPassword }}</div>
  <div class="profile-update-container grid grid-cols-2 gap-x-0 sm:gap-x-10 mt-12">
    <div class="col-span-2 w-full md:w-7/12">
      <div>
        <div class="flex flex-col sm:flex-row mt-8">
          <label for="oldPassword" class="input-label mb-2 sm:mb-0 flex items-center">
            {{ content.oldPassword }}
          </label>
          <div class="relative w-full col-span-4 sm:col-span-3">
            <div class="show-password" @click="showPassword = !showPassword">
              <i class="far fa-eye-slash" v-if="showPassword"></i>
              <i class="far fa-eye" v-else></i>
            </div>
            <input
              :type="showPassword ? 'text' : 'password'"
              class="form-input"
              v-model="oldPassword"
              id="oldPassword"
              placeholder="******"
            />
          </div>
        </div>
      </div>
      <div>
        <div class="flex flex-col sm:flex-row mt-8">
          <label for="newPassword" class="input-label mb-2 sm:mb-0 flex items-center">{{
            content.newPassword
          }}</label>
          <div class="relative w-full col-span-4 sm:col-span-3">
            <div class="show-password" @click="showNewPassword = !showNewPassword">
              <i class="far fa-eye-slash" v-if="showNewPassword"></i>
              <i class="far fa-eye" v-else></i>
            </div>
            <input
              :type="showNewPassword ? 'text' : 'password'"
              class="form-input"
              v-model="newPassword"
              id="newPassword"
              placeholder="******"
            />
          </div>
        </div>
        <p class="error mt-2 h-3">
          {{ error }}
        </p>
      </div>
      <!-- <div>
        <div class="flex flex-col sm:flex-row mt-8">
          <label
            for="confirmPassword"
            class="input-label mb-2 sm:mb-0 flex items-center"
            >{{ content.confirmPassword }}</label
          >
          <div class="relative w-full col-span-4 sm:col-span-3">
            <div
              class="show-password"
              @click="showConfrimPassword = !showConfrimPassword"
            >
              <i class="far fa-eye-slash" v-if="showConfrimPassword"></i>
              <i class="far fa-eye" v-else></i>
            </div>
            <input
              :type="showConfrimPassword ? 'text' : 'password'"
              class="form-input"
              v-model="confirmPassword"
              id="confirmPassword"
              placeholder="******"
            />
          </div>
        </div>
      </div> -->
      <Alert
        type="info"
        message="Passwords must be 8 Character long, contain  at least 1 letter and 1 number and a special character "
      />
      <div>
        <div class="flex justify-end my-8">
          <button
            class="header-btn hover:text-bluePrimary text-white bg-bluePrimary lora-bold whitespace-nowrap hover:bg-blueSecondary duration-300"
            @click="update"
          >
            {{ content.updatePassword }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-span-2 w-full md:w-7/12">
      <!-- <div class="page-title mt-12">
        {{ content.moreOptions }}
      </div> -->
      <div
        class="arrow-more-btn flex justify-between items-center mt-8 mb-16"
        @click="forgotPassword()"
      >
        {{ content.forgetPassword }}
        <i
          class="fas text-size-12 fa-chevron-right btn-arrow-icon text-base transition-all delay-75 ms-8"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  data() {
    return {
      showPassword: false,
      showNewPassword: false,
      oldPassword: "",
      newPassword: "",
      error: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    async update() {
      this.error = null;
      if (this.newPassword.length < 8) {
        this.error = this.content.passwordLength;
        return;
      }

      try {
        await this.$store.dispatch("user/updatePassword", {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        });
        this.oldPassword = "";
        this.newPassword = "";
        this.showPassword = false;
        this.showNewPassword = false;
      } catch (error) {
        console.log(error);
      }
    },
    async forgotPassword() {
      try {
        await this.$store.dispatch("auth/forgotPassword", this.user.email);
        this.$store.dispatch("feedback/setFeedback", {
          type: "success",
          title: this.content.success,
          message: this.content.checkEmail,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
